<template>
  <CBox>
    <HeaderCompetition
      v-if="currentAppType.view_type != 'RECRUITMENT' && isAuthenticated" 
    />
    <CBox bg="bgMain" h="100%" minHeight="calc(100vh - 54px)">
      <CBox
        pt="5px"
        pb="5px"
        maxW="1080px"
        mx="auto"
        :px="['0.7em', '1em', '1em', 'auto']"
      >
        <router-view />
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import HeaderCompetition from "@/components/header/HeaderCompetition.vue";
import { CBox } from "@chakra-ui/vue";
import { GET_USER } from "@/store/auth.module";

export default {
  name: "LayoutDashboard",
  components: {
    HeaderCompetition,
    CBox,
  },
  computed: {
    currentAppType() {
      return this.$store.getters.currentAppType;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  async beforeMount() {
    await this.getData();
    // this.$store.dispatch(GET_USER)
  },  
  methods: {
    async getData() {      
      // if (!this.isAuthenticated) {
      // this.isLoading = true;
      // await this.$store.dispatch(GET_JWT);
      // }
      setTimeout(async () => {
        // await this.$store.dispatch(GET_USER);
        // this.isLoading = false;
      }, 100);
      await this.$store.dispatch(GET_USER);
    },
  },
};
</script>
